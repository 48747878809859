import React, { Component, useState } from "react";
import "../Css/dumpyarddiv.css";
import "../Css/design-process.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import download from "../Assets/download.svg";

class DumpardDiv extends Component {
  render() {
    return (
      <div className="dump-sec">
        <span className="circle-3"></span>
        <div className="div-ex">
          <p>I believe</p>
          <h1>“There is no such thing like a Bad Design”</h1>
          <div className="dump-button">
            <a
              className="button2"
              href="https://drive.google.com/file/d/1EioeL0-HlP9ruu74Vg4sSn0vItmH47GR/view?usp=share_link"
              target="_blank"

              style={{ backgroundColor: "#0057FF" }}
            >
              <img
                className="download"
                style={{ color: "white", border: "0px" }}
                src={download}
              />
              RESUME
            </a>
            <Link className="button2" to="/about">
              ABOUT ME
            </Link>
          </div>
        </div>
        <div>
          <p>
            There's is no such thing like a
            <span >
              {" "}
              bad design or a bad designer
            </span>
            . You just have to know stuff. It's always a learning and evolving
            process.
          </p>
          <p>
            I am a firm believer that
            <span >
            {" "}design is more than just aesthetics
            </span>
            . It's about solving problems that others don't have; it's about
            creating something durable and functional. This makes the difference
            between
            <span >
            {" "}good and best-in-class products.
            </span>
          </p>
          
        </div>
      </div>
    );
  }
}

export default DumpardDiv;

import React, { useState } from "react";
import "../Css/about.css";
import "../Css/dumpyard.css";
import Nav from "./Navigation";
import Pattern from "../Assets/pattern.svg";
import Pattern2 from "../Assets/pattern2.svg";
import Contact from "./contact";
import Footer from "./footer";
import "../Css/Nav.css";
import { Scrollama, Step } from "react-scrollama";
import tech from "../Assets/tech.png";
import des from "../Assets/design.png";
import pro from "../Assets/product.png";
import lenskart from "../Assets/lenskart.png";
import promo from "../Assets/promo.png";
import woo from "../Assets/woo.png";
import lp from "../Assets/lp.png";
import hcode from "../Assets/hcode.png";
import ReactToolTip from "./tooltip";

import { Menu, MenuItem } from "@szhsin/react-menu";

import { Link } from "react-router-dom";
import { Link as Link2 } from "react-scroll";
import menu from "../Assets/Menu.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function About() {
  const [currentStepIndex, setCurrentStepIndex] = useState(null);

  const [align, setAlign] = useState("end");
  const [position, setPosition] = useState("end");
  const [viewScroll, setViewScroll] = useState("close");

  const onStepEnter = ({ data }) => {
    setCurrentStepIndex(data);
  };
  var settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    pauseOnHover: false,
    cssEase: "linear",
  };
  return (
    <div className="about-page">
      <span className="about-span"></span>
      <Nav />
      <div>
        <div className="header-para-about">
          <div className="text-header-about">
            <h1>Helping brands thrive</h1>
            <h1 style={{ textDecoration: "none", color: "#0057FF" }}>
              in the digital world
            </h1>
            <img src={Pattern} className="pattern" />
            <img src={Pattern2} className="pattern2" />
          </div>

          <div className="ng-about">
            <span className="circle4"></span>
            <span className="circle5"></span>
            <Scrollama onStepEnter={onStepEnter} offset={0.3}>
              <Step data={1} key={1}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 1 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 1 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      I'm Harshit, skilled as a multidisciplinary designer. Like
                      every other Engineer, I'm not doing what I am supposed to
                      do. I love exploring new design trends and am often amazed
                      by the progress we as a human species have made so far in
                      recent years (apart from the headphone-jack part!!).
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={2} key={2}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 2 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 2 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      I started designing right from Grade 10. I would spend the
                      whole day designing and editing images on Photoshop. I
                      started designing right from Grade 10. I would spend the
                      whole day designing and editing images on Photoshop. In
                      first year of my college, my friends and I decided to make
                      video on YouTube.
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={3} key={3}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 3 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 3 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      As a result, I started loving the creative side of myself.
                      Learnt visual effects, film making and video editing. We
                      tried to do something different each time we made a short
                      film. You can still watch all of them on my YouTube
                      Channel. Not proud of them, but proud of the effort we had
                      put in, haha.
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={4} key={4}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 4 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 4 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      Was elected as the Head of the Design Team in college fest
                      and was in charge of recruiting members to the team,
                      delegating work, and overseeing all the work. It included
                      creating posters, videos, animations, etc., for the
                      college fest. I was told they had never seen anyone of my
                      caliber until they saw my work.
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={5} key={5}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 5 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 5 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      I started learning coding as well in case nothing goes
                      right. In the process, I was leaning Python and React JS.
                      Hopefully I made couple of decent projects with those.
                      This website is result of that.
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={6} key={6}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 6 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 6 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      As a design enthusiast, I consistently stay ahead of
                      industry trends, immersing myself in innovative
                      inspiration to ensure my creative work remains fresh,
                      exciting, and at the forefront of the field.
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={7} key={7}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 7 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 7 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      I thrive on the pursuit of perfection, meticulously
                      attending to every detail. It's this relentless commitment
                      that has propelled me to the top of my game. Get ready to
                      experience sheer perfection from my meticulous approach
                      and unwavering dedication.
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={8} key={8}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 8 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 8 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      In my creative journey, impact reigns supreme. Through
                      meticulous craftsmanship, I strive to create projects that
                      leave the world in awe, igniting inspiration and making
                      ripples of possibility. With every brushstroke, pixel, and
                      idea, I aim to shake foundations and evoke a spark of
                      inspiration in all who encounter my work.
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={9} key={9}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 9 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 9 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      I pick up projects or work on things that can make a huge
                      impact. The degree of impact is what matters to me. I
                      often ask myself, 'If I do this. How big of an impact will
                      it make?'
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={10} key={10}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 10 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 10 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      In order to learn and grow together let's connect and chat
                      over.Together, we can explore new ideas, exchange
                      insights, and foster a collaborative environment that
                      fuels our personal and professional development.
                    </p>
                  </div>
                </div>
              </Step>
            </Scrollama>
          </div>
          <div className="ng-about2">
            <span className="circle4"></span>
            <span className="circle5"></span>
            <Scrollama onStepEnter={onStepEnter} offset={0.6}>
              <Step data={1} key={1}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 1 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 1 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      I'm Harshit, skilled as a multidisciplinary designer. Like
                      every other Engineer, I'm not doing what I am supposed to
                      do. I love exploring new design trends and am often amazed
                      by the progress we as a human species have made so far in
                      recent years (apart from the headphone-jack part!!).
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={2} key={2}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 2 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 2 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      I started designing right from Grade 10. I would spend the
                      whole day designing and editing images on Photoshop. I
                      started designing right from Grade 10. I would spend the
                      whole day designing and editing images on Photoshop. In
                      first year of my college, my friends and I decided to make
                      video on YouTube.
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={3} key={3}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 3 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 3 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      As a result, I started loving the creative side of myself.
                      Learnt visual effects, film making and video editing. We
                      tried to do something different each time we made a short
                      film. You can still watch all of them on my YouTube
                      Channel. Not proud of them, but proud of the effort we had
                      put in, haha.
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={4} key={4}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 4 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 4 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      Was elected as the Head of the Design Team in college fest
                      and was in charge of recruiting members to the team,
                      delegating work, and overseeing all the work. It included
                      creating posters, videos, animations, etc., for the
                      college fest. I was told they had never seen anyone of my
                      caliber until they saw my work.
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={5} key={5}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 5 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 5 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      I started learning coding as well in case nothing goes
                      right. In the process, I was leaning Python and React JS.
                      Hopefully I made couple of decent projects with those.
                      This website is result of that.
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={6} key={6}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 6 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 6 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      As a design enthusiast, I consistently stay ahead of
                      industry trends, immersing myself in innovative
                      inspiration to ensure my creative work remains fresh,
                      exciting, and at the forefront of the field.
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={7} key={7}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 7 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 7 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      I thrive on the pursuit of perfection, meticulously
                      attending to every detail. It's this relentless commitment
                      that has propelled me to the top of my game. Get ready to
                      experience sheer perfection from my meticulous approach
                      and unwavering dedication.
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={8} key={8}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 8 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 8 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      In my creative journey, impact reigns supreme. Through
                      meticulous craftsmanship, I strive to create projects that
                      leave the world in awe, igniting inspiration and making
                      ripples of possibility. With every brushstroke, pixel, and
                      idea, I aim to shake foundations and evoke a spark of
                      inspiration in all who encounter my work.
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={9} key={9}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 9 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 9 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      I pick up projects or work on things that can make a huge
                      impact. The degree of impact is what matters to me. I
                      often ask myself, 'If I do this. How big of an impact will
                      it make?'
                    </p>
                  </div>
                </div>
              </Step>
              <Step data={10} key={10}>
                <div
                  style={{
                    position: "sticky",
                    opacity: currentStepIndex === 10 ? 1 : 0.2,
                    transition: "100ms, ease-in-out 100ms",
                  }}
                >
                  <div
                    className="about-div"
                    style={{
                      opacity: currentStepIndex === 10 ? 1 : 0.2,
                      transition: "200ms, ease-in 200ms",
                    }}
                  >
                    <p>
                      In order to learn and grow together let's connect and chat
                      over.Together, we can explore new ideas, exchange
                      insights, and foster a collaborative environment that
                      fuels our personal and professional development.
                    </p>
                  </div>
                </div>
              </Step>
            </Scrollama>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1 className="head-exp">Skillset I Possess</h1>
      <div className="expertise">
        <div className="exp1">
          <img src={tech} className="exp" />
          <h1>Technology</h1>
          <p>
            With a solid foundation in technology, I have honed my skills in
            Python, ReactJS, and JavaScript, equipping me with a versatile
            toolkit to tackle complex challenges and how to handle API calls.
          </p>
        </div>
        <div className="exp1">
          <img src={des} className="exp" />
          <h1>Design</h1>
          <p>
            Design has been a integral part throughout my career. From associate
            to leading a product i have done it all. My affection towards
            innovation and new idea drive the designer in me.
          </p>
        </div>
        <div className="exp1">
          <img src={pro} className="exp" />
          <h1>Product</h1>
          <p>
            I have gained valuable experience in driving successful product
            strategies, overseeing the entire product lifecycle, and
            collaborating with cross-functional teams. My expertise lies in
            identifying market opportunities, defining product wireframes, and
            feature management.
          </p>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="container-div2">
        <h1>Proud to have worked with</h1>
        <div className="company-images">
          <ReactToolTip
            theme="dark"
            position="bottom"
            title="In my current role at Lenskart, 
            I am responsible for handling the Checkout Experience at the Point of Sale 
            (POS) and Store Kiosk. 
            As part of this role, I actively engage with users, 
            gathering valuable feedback to evaluate and improve the checkout process."
          >
            <img src={lenskart} className="company img1" />
          </ReactToolTip>
          <ReactToolTip
            theme="dark"
            position="bottom"
            title="As the Lead Product Designer for the Promo app, 
            I developed an e-commerce plugin enabling brands to offer 
            personalized discounts in exchange for user-generated content. 
            Through collaboration and user-centric design, I crafted a 
            seamless and engaging experience, leveraging research, 
            wireframing, and prototyping."
          >
            <img src={promo} className="company img2" />
          </ReactToolTip>
          <ReactToolTip
            theme="dark"
            position="bottom"
            title="
            As part of my responsibilities, I played a key role in the development and 
            launch of WOO, 
            a unified payment application in Nigeria. Specifically, 
            I focused on user experience research and client management, 
            leveraging insights to shape the product and ensure its successful 
            delivery to market."
          >
            <img src={woo} className="company img3" />
          </ReactToolTip>

          <ReactToolTip
            theme="dark"
            position="bottom"
            title="As a multidisciplinary designer at Little Planet Studio, 
            I excelled in cross-border collaborations, delivering successful 
            outcomes across branding, product delivery, and meeting the 
            unique needs of international clients."
          >
            <img src={lp} className="company img4" />
          </ReactToolTip>
          <ReactToolTip
            theme="dark"
            position="bottom"
            title="
            As the Lead Product Designer at HCODE, 
            I led the design and development of diverse applications, 
            fostering close collaboration with the internal team for 
            seamless integration and exceptional end products."
          >
            <img src={hcode} className="company img5" />
          </ReactToolTip>
        </div>
      </div>
      <section id="contact">
        <Contact />
        <Footer />
      </section>
      <Menu
        menuButton={<img className="menu" src={menu} />}
        key="top"
        direction="top"
        align={align}
        position={position}
        viewScroll={viewScroll}
        gap={12}
        transition
      >
        <MenuItem>
          <Link to="/" style={{ textDecoration: "none", color: "White" }}>
            HOME
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/work" style={{ textDecoration: "none", color: "White" }}>
            WORK
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/about" style={{ textDecoration: "none", color: "White" }}>
            ABOUT ME
          </Link>
        </MenuItem>
        <MenuItem>
          <Link2
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            style={{ textDecoration: "none", color: "White" }}
          >
            LET'S CONNECT
          </Link2>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default About;

{
  /* <div className="about-text">
          <span className="circle3"></span>

          <span className="circle4"></span>
          <h3>Product design is my forte!😎🤏</h3>
          <p>
            I'm Harshit, skilled as a multidisciplinary designer. Like every
            other Engineer, I'm not doing what I am supposed to do. I love
            exploring new design trends and am often amazed by the progress we
            as a human species have made so far in recent years (apart from the
            headphone-jack part!!). I always used to think that my ideas weren’t
            good enough, but then I realized that if I don’t try it, I have no
            clue how it will turn out. If an idea or a concept doesn’t come
            alive like I imagined, it’s not the end of the world.
          </p>
        </div>

        <div className="about-text">
          <h3>Because with great power, comes great responsibility.🕷️📚📋</h3>
          <p>
            I'm a consumer product designer that specialises in turning
            complicated data into easy and appealing representations.I know how
            to guarantee user demands which are linked with corporate goals
            thanks to my significant expertise working with engineers,
            executives, and marketing teams. I have started reflecting on my
            ideas and thoughts through designs and medium of words recently so
            spelling and grammar mistakes are very often. You can write me back
            if you spot any and don't want to live anymore.
          </p>
        </div>
          <div className="peace">
        <Slider {...settings}>
          <div>
            <img src={tech} className="slideShow" />
          </div>
          <div>
            <img src={tech} className="slideShow" />
          </div>
          <div>
            <img src={tech} className="slideShow" />
          </div>
          <div>
            <img src={tech} className="slideShow" />
          </div>
          <div>
            <img src={tech} className="slideShow" />
          </div>
          <div>
            <img src={tech} className="slideShow" />
          </div>
        </Slider>
        <div className="peace2">
          <h1>PEACE OF MIND</h1>
          <p>
            A person who loves nature, music, exercise, sports, and general
            well-being. I do my best to live the most balanced life possible.
            Life is a gift that we must not take for granted. Nothing is more
            important to me than the peace of mind of my loved ones and the
            being in the best mental and physical shape possible.
          </p>
        </div>
      </div>
        
        */
}

import React, { useEffect, useState } from "react";
import "../App.css";
import Nav from "./Navigation";
import Header from "./Header2";
import DumpardDiv from "./dumpyard-div";
import Contact from "./contact";
import Work from "./MainWork";
import OtherWork from "./OtherWork";
import Footer from "./footer";
import { Fade } from "react-awesome-reveal";
import Magic from "./magic";
import lenskart from "../Assets/lenskart.png";
import promo from "../Assets/promo.png";
import woo from "../Assets/woo.png";
import lp from "../Assets/lp.png";
import hcode from "../Assets/hcode.png";
import ReactToolTip from "./tooltip";
import { Menu, MenuItem } from "@szhsin/react-menu";
import HeaderImage from "../Assets/h_pattern.svg";
import { Link } from "react-router-dom";
import { Link as Link2 } from "react-scroll";
import menu from "../Assets/Menu.svg";

function Main() {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const [align, setAlign] = useState("end");
  const [position, setPosition] = useState("end");
  const [viewScroll, setViewScroll] = useState("close");

  window.addEventListener("scroll", checkScrollTop);

  return (
    <div className="App">
      <Nav />
      <span className="circle1"></span>
      <span className="circle2"></span>
      <img className="header-image" src={HeaderImage} />
      <Header />
      <section id="dumpyard" style={{backgroundImage :"linear-gradient(0deg, rgb(0 87 255 / 13%), rgb(0 87 255 / 0%));"}}>
        <DumpardDiv />
      </section>

      <Work />
      <OtherWork />
      <div className="container-div2">
        <h1>Proud to have worked with</h1>
        <div className="company-images">
          <ReactToolTip
            theme="dark"
            position="bottom"
            title="In my current role at Lenskart, 
            I am responsible for handling the Checkout Experience at the Point of Sale 
            (POS) and Store Kiosk. 
            As part of this role, I actively engage with users, 
            gathering valuable feedback to evaluate and improve the checkout process."
          >
            <img src={lenskart} className="company img1" />
          </ReactToolTip>
          <ReactToolTip
            theme="dark"
            position="bottom"
            title="As the Lead Product Designer for the Promo app, 
            I developed an e-commerce plugin enabling brands to offer 
            personalized discounts in exchange for user-generated content. 
            Through collaboration and user-centric design, I crafted a 
            seamless and engaging experience, leveraging research, 
            wireframing, and prototyping."
          >
            <img src={promo} className="company img2" />
          </ReactToolTip>
          <ReactToolTip
            theme="dark"
            position="bottom"
            title="
            As part of my responsibilities, I played a key role in the development and 
            launch of WOO, 
            a unified payment application in Nigeria. Specifically, 
            I focused on user experience research and client management, 
            leveraging insights to shape the product and ensure its successful 
            delivery to market."
          >
            <img src={woo} className="company img3" />
          </ReactToolTip>

          <ReactToolTip
            theme="dark"
            position="bottom"
            title="As a multidisciplinary designer at Little Planet Studio, 
            I excelled in cross-border collaborations, delivering successful 
            outcomes across branding, product delivery, and meeting the 
            unique needs of international clients."
          >
            <img src={lp} className="company img4" />
          </ReactToolTip>
          <ReactToolTip
            theme="dark"
            position="bottom"
            title="
            As the Lead Product Designer at HCODE, 
            I led the design and development of diverse applications, 
            fostering close collaboration with the internal team for 
            seamless integration and exceptional end products."
          >
            <img src={hcode} className="company img5" />
          </ReactToolTip>
        </div>
      </div>

      {/* <Expertise /> */}

      <Magic />
      <Fade delay={20} duration={600} triggerOnce>
        <section id="contact">
          <Contact />
          <Footer />
        </section>
      </Fade>
      <Menu
        menuButton={<img className="menu" src={menu} />}
        key="top"
        direction="top"
        align={align}
        position={position}
        viewScroll={viewScroll}
        gap={12}
        transition
      >
        <MenuItem>
          <Link to="/" style={{ textDecoration: "none", color: "White" }}>
            HOME
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/work" style={{ textDecoration: "none", color: "White" }}>
            WORK
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to="/about"
            target="_blank"
            style={{ textDecoration: "none", color: "White" }}
          >
            ABOUT ME
          </Link>
        </MenuItem>
        <MenuItem>
          <Link2
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            style={{ textDecoration: "none", color: "White" }}
          >
            LET'S CONNECT
          </Link2>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default Main;

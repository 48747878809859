import React from "react";

export default function Footer() {
  return (
    <div>
      <hr className="solid"  style={{
               
                opacity: 0.2,

              
              }}/>
      <div className="footer">
        <div className="footer-left">
          <p>
            I Designed and Coded this website from scratch © Harshit Sharma.{" "}
          </p>
          
        </div>
      </div>
    </div>
  );
}

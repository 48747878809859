import React, { useState } from "react";
import "../Css/contact.css";
import copy from "copy-to-clipboard";


function Contact() {
  const copyToClipboard = () => {
    copy("harshitsharma34@gmail.com");
  };
  const [showStatements, setShowStatements] = useState(false);

  const timeId = setTimeout(() => {
    // After 3 seconds set the show value to false
    setShowStatements(false);
  }, 3000);

  const showDiv = () => {
    setShowStatements(true);
  };

  let allStatements;
  if (showStatements) {
    allStatements = <div className="success-copy">Copied 😊</div>;
  }

  return (
    <div className="contact-div">
      <p className="talk">WANT TO TALK ABOUT SOMETHING?</p>
      <div className="get-in-touch">
        <h2>
          <a href="mailto:harshitsharma34@gmail.com">GET IN TOUCH!</a>
        </h2>

        <div
          className="mask"
          onClick={() => {
            copyToClipboard();
            showDiv();
          }}
        >
          <div class="marquee-contact">
            <p>
              <h1>harshitsharma34@gmail.com - Copy to clipboard -&nbsp;</h1>
            </p>
            <p>
              <h1>harshitsharma34@gmail.com - Copy to clipboard -&nbsp;</h1>
            </p>
            <p>
              <h1>harshitsharma34@gmail.com - Copy to clipboard -&nbsp;</h1>
            </p>
            <p>
              <h1>harshitsharma34@gmail.com - Copy to clipboard -&nbsp;</h1>
            </p>
          </div>
        </div>
        {allStatements}
      </div>
      <ul>
        <li>
          <a href="https://harshits.medium.com/" target="_blank">
            Medium
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/harshitsharma34/"
            target="_blank"
          >
            LinkedIn
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/harshit.shaxma/" target="_blank">
            Instagram
          </a>
        </li>
        <li>
          <a href="https://twitter.com/bruisedwaynne" target="_blank">
            Twitter
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Contact;
